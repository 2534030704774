import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CheckBox from "@material-ui/core/Checkbox";
import CropperImage from "../CropperImage.jsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { Modal, Button, Collapse } from "react-bootstrap"
import { CreateMenuGroupV3 } from "./CreateMenuGroupV3.js";
import { useIntl } from "react-intl";
import { validateLanguage } from "../../../service/validationLanguage.js";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export const CreateMenuNewV3 = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const classes = useStyles();
    suhbeader.setTitle(intl.formatMessage({ id: "113278", defaultMessage: "Menu" }));
    const history = useHistory();
    const [group_id, setGroup_id] = useState();
    const [template_id, setTemplate_id] = useState();
    const [template_name, setTemplateName] = useState();
    const [template, setTemplate] = useState();
    const [optionTemplate, setOptionTemplate] = useState([]);
    const [editTemplate, setEditTemplate] = useState(true);
    const [cloneTemplate, setCloneTemplate] = useState(false);
    const [item_name, setItem_name] = useState("");
    const [item_name_en, setItem_name_en] = useState("");
    const [item_name_cn, setItem_name_cn] = useState("");
    const [item_name_jp, setItem_name_jp] = useState("");
    const [item_name_kr, setItem_name_kr] = useState("");
    const [item_name_id, setItem_name_id] = useState("");
    const [description, setDescription] = useState("");
    const [description_en, setDescription_en] = useState("");
    const [description_cn, setDescription_cn] = useState("");
    const [description_jp, setDescription_jp] = useState("");
    const [description_kr, setDescription_kr] = useState("");
    const [description_id, setDescription_id] = useState("");
    const [description_receipt, setDescription_receipt] = useState("");
    const [item_image_path, setItem_image_path] = useState();
    const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
    const [uom, setUom] = useState();
    const [price, setPrice] = useState();
    const [state, setState] = useState(true);
    const [changeOption, setChangeOption] = useState(false);
    const [changeName, setChangeName] = useState(false);
    const [group, setGroup] = useState([]);
    const [displayOrder, setDisplayOrder] = useState(1)
    const [show, setShow] = useState(false);
    const [printer_zone_id, setprinter_zone_id] = useState();
    const [zoneprinter_data, setzoneprinter_data] = useState([]);
    const [tagData, setTagData] = useState([]);
    const [tagItem, setTagItem] = useState([]);
    const [enable_cn, setEnable_cn] = useState(false);
    const [barcode, setBarcode] = useState('');
    const [data, setData] = useState([]);
    const [priceGroup, setPriceGroup] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [salesChannel, setSalesChannel] = useState([]);
    const [stocklevel, setStockLevel] = useState(false);
    const [stockLevelUnit, setStockLevelUnit] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [visibleOnlyEmployee, setVisibleOnlyEmployee] = useState(false)
    const [zone, setZone] = useState([])

    const [stateItem_image_path, setStateItem_image_path] = useState({
        previewItem_image_path: null,
        rawItem_image_path: null,
        imageURLItem_image_path: null,
    });

    const AlertError = (message) => {
        // Swal.fire("Menu Management!", message, "error");
        Swal.fire(
            intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }),
            message,
            "error"
        );
        setIsLoading(false)
    };

    useEffect(() => {
        getGroup();
        getZonePrint();
        getTemplateGroup();
        getBuffet();
        checkEnableCN();
        setProps();
        getZone();
    }, []);

    const checkEnableCN = () => {
        let temp = localStorage.getItem('is_enable_cn')
        if (temp == '1') {
            setEnable_cn(true)
        }
    };

    const getZonePrint = async () => {
        let apiUrls = `${baseURL}/management/printerzone?limit=10000&page=1`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                "Accept-Language": "th",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                setzoneprinter_data(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getTemplateGroup = async () => {
        let apiUrls = `${baseURL}/management/templatelist`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                "Accept-Language": "th",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                setTemplate(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        console.log("template ->>", template)
    }, [template])


    const getBuffet = async () => {
        let apiUrls = `${baseURL}/management/menubuffet?page=1&limit=0`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                "Accept-Language": "th",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                setTagData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getGroup = async () => {
        let apiUrls = `${baseURL}/management/group?limit=10000&page=1`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                "Accept-Language": "th",
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async (res) => {
                setGroup(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getZone = async () => {
        let apiUrls = `${baseURL}/management/tablezone?limit=100&page=1`;
        let token = localStorage.getItem("Token");
        await axios({
          method: "get",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async (res) => {
            setZone(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
    };

    useEffect(() => {

        console.log("zone ->>", zone)
    }, [zone])
    const filteredZone = zone.map(({ id, zone_name, zone_name_en, zone_desc, zone_desc_en }) => ({
        id,
        zone_name,
        zone_name_en,
        zone_desc,
        zone_desc_en
    }));

    const handleChangeGroup = (event) => {
        setGroup_id(event.target.value);
    };
    const handleChangeStockLevel = () => {
        setStockLevel(!stocklevel);
    };

    // const handleChangeCheckPrice = (event) => {
    //   if (event.target.checked) {
    //     setIsChecked(!isChecked);
    //     setPriceState();
    //   }else{
    //     setIsChecked(!isChecked);
    //     setPriceGroup(null);
    //   }

    // };
    const handleChangeCheckPrice = (event) => {
        const checked = event.target.checked;
        setIsChecked(checked);

        if (checked) {
            // กำหนดค่าเริ่มต้นเป็นราคาหลักเมื่อเช็คบ็อกซ์ถูกเลือก
            const updatedData = data.map((item) => ({
                ...item,
                priceGroup: price // ใช้ price เป็นค่าเริ่มต้น
            }));
            setData(updatedData);
        }
    };

    const handleChangeTemplate = (event) => {
        setTemplate_id(event.target.value);
        let template_select = template.find(arr => arr.id == event.target.value)
        console.log('template_select xxxxxxxxxxxxx', template_select);
        // console.log(event.target.value);
        if (template_select) {
            setOptionTemplate(template_select.options_template)
        } else {
            setOptionTemplate([])
        }
        if (event.target.value === '') {
            setEditTemplate(true)
        } else {
            setEditTemplate(false)
        }

        setCloneTemplate(false)
    };

    const handleChangeItem_image_path = async (e) => {
        if (e.target.files.length) {
            e.preventDefault();
            let file = e.target.files[0];
            e.target.value = '';
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;
                    var width = this.width;
                    if (height < 512 || width < 512) {
                        Swal.fire(
                            "Upload !",
                            "At least you can upload a 512*512 photo size. !! ",
                            "error"
                        );
                        e.target.value = '';
                        return false;
                    } else {
                        setStateItem_image_path({
                            rawItem_image_path: file,
                            imageURLItem_image_path: e.target.result,
                        });
                        e.target.value = '';
                        return true;
                    }
                };
            };
        }
    };

    const handleImageUploadItem_image_path = async (file) => {
        var formdata = new FormData();
        formdata.append("image", file, URL.createObjectURL(file));
        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };
        fetch(`${baseURL}/upload/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let res = JSON.parse(result);
                setStateItem_image_path({
                    previewItem_image_path: null,
                    rawItem_image_path: null,
                    imageURLItem_image_path: null,
                });
                setItem_image_path(`${imageURL}/${res.filename}`);
                console.log('${imageURL} ', imageURL)
                setNameImageItem_image_path(res.filename);
            })
            .catch((error) => console.log("error", error));
    };

    // const handleChangeItem_image_path = async (e) => {
    //   if (e.target.files.length) {
    //     e.preventDefault();
    //     let file = e.target.files[0];
    //     let reader = new FileReader();

    //     reader.readAsDataURL(file);
    //     reader.onload = function () {
    //       let imageSrc = reader.result;
    //       processImage(file, imageSrc);
    //     };

    //     e.target.value = "";
    //   }
    // };

    // const processImage = (file, imageSrc) => {
    //   let image = new Image();
    //   image.src = imageSrc;

    //   image.onload = function () {
    //     let height = this.height;
    //     let width = this.width;

    //     if (height < 512 || width < 512) {
    //       Swal.fire(
    //         "Upload !",
    //         "At least you can upload a 512*512 photo size. !!",
    //         "error"
    //       );
    //     } else {
    //       setStateItem_image_path({
    //         rawItem_image_path: file,
    //         imageURLItem_image_path: imageSrc,
    //         previewItem_image_path: imageSrc,
    //       });
    //     }
    //   };
    // };

    // const handleImageUploadItem_image_path = async (file) => {
    //   var formdata = new FormData();
    //   formdata.append("image", file, URL.createObjectURL(file));
    //   var requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     redirect: "follow",
    //   };
    //   fetch(`${baseURL}/upload/`, requestOptions)
    //     .then((response) => response.text())
    //     .then((result) => {
    //       let res = JSON.parse(result);
    //       if (res.filename) {
    //         setItem_image_path(`${imageURL}/${res.filename}`);
    //         setNameImageItem_image_path(res.filename);
    //         setStateItem_image_path((prevState) => ({
    //           ...prevState,
    //           rawItem_image_path: null,
    //           imageURLItem_image_path: null,
    //         }));
    //       } else {
    //         console.log("Upload failed:", res);
    //       }
    //     })
    //     .catch((error) => console.log("error", error));
    // };

    const removeImageItem_image_path = async () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
        setItem_image_path("");
    };


    const handleCancelUploadItem_image_path = () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
    };


    const handleChangeSwitch = () => {
        setState(!state);
    };

    const handleChangeOption = () => {
        setChangeOption(!changeOption);
    };

    const handleChangeNameOption = () => {
        setChangeName(!changeName);
    };

    const handleChangezoneprinter = (event, values) => {
        if (values) {
            setprinter_zone_id(values.id);
        } else {
            setprinter_zone_id("");
        }
    };

    const handleVisibility = () => {
        setVisibleOnlyEmployee(!visibleOnlyEmployee)
    }

    // const renderInputField = (item, index) => {
    //   return (
    //     <div key={index}>
    //       <div className="form-inline" style={{ marginBottom: "25px" }}>
    //         <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
    //           {item.salesch_name_en}:{" "}
    //         </p>
    //         <input
    //           name={item.id}
    //           type="number"
    //           style={{
    //             height: "38px",
    //             width: "530px",
    //             backgroundColor: "#F4F6F9",
    //             borderRadius: "5px",
    //             borderWidth: "0",
    //           }}
    //           value={isChecked ? price : priceGroup}
    //           onChange={(e) => {
    //             updateFieldChanged(index, e.target.value, "priceGroup");

    //           }}
    //         />
    //       </div>
    //     </div>
    //   );
    // };
    const renderInputField = (item, index) => {
        return (
            <div key={index}>
                <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                        {item.salesch_name_en}:{" "}
                    </p>
                    <input
                        name={item.id}
                        type="number"
                        style={{
                            height: "38px",
                            width: "530px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                        }}
                        value={item.priceGroup || ""} // ใช้ค่า priceGroup ที่ถูกตั้งไว้
                        onChange={(e) => { 
                            // อัปเดตเฉพาะราคาของช่องนั้น ๆ
                            const newPrice = e.target.value;
                            updateFieldChanged(index, newPrice, "priceGroup");
                        }}
                    />
                </div>
            </div>
        );
    };

    const onSubmit = async () => {
        console.log("data on submit ====>", data);

        if (item_name === undefined || item_name === "") {
            // return AlertError("please insert item name.");
            return AlertError(intl.formatMessage({ id: "113546", defaultMessage: "Please insert Thai name." }));
        }

        // ของเก่า เป็นแบบผสม ไทยอังกฤษ เลยต้องเอา validate ออก
        // if (!validateLanguage(item_name, "Thai", intl, AlertError)) return;
        // if (description && !validateLanguage(description, "Thai", intl, AlertError)) return;

        // if (item_name_en && !validateLanguage(item_name_en, "English", intl, AlertError)) return;
        // if (description_en && !validateLanguage(description_en, "English", intl, AlertError)) return;

        // if (item_name_cn && !validateLanguage(item_name_cn, "Chinese", intl, AlertError)) return;
        // if (description_cn && !validateLanguage(description_cn, "Chinese", intl, AlertError)) return;

        // if (item_name_jp && !validateLanguage(item_name_jp, "Japanese", intl, AlertError)) return;
        // if (description_jp && !validateLanguage(description_jp, "Japanese", intl, AlertError)) return;

        // if (item_name_kr && !validateLanguage(item_name_kr, "Korean", intl, AlertError)) return;
        // if (description_kr && !validateLanguage(description_kr, "Korean", intl, AlertError)) return;

        // if (item_name_id && !validateLanguage(item_name_id, "Indian", intl, AlertError)) return;
        // if (description_id && !validateLanguage(description_id, "Indian", intl, AlertError)) return;

        if (stocklevel === true) {
            if (stockLevelUnit === undefined || stockLevelUnit === "" || stockLevelUnit === 0) {
                return AlertError("please insert stock level units.");
            }
        }

        if (group_id === undefined || group_id === "") {
            return AlertError("please select group.");
        }
        if (printer_zone_id === undefined || printer_zone_id === "" || printer_zone_id === null) {
            return AlertError("please select printer zone")
        }

        if (price === undefined || price === "") {
            return AlertError("please insert price.");
        }

        if (description_receipt === undefined || description_receipt === "") {
            return AlertError("please insert description receipt.");
        }

        if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0 || Number(displayOrder) < 0) {
            return AlertError('please insert display order.')
        }

        let get_shop_id = localStorage.getItem("shop_id");
        let get_branch_id = localStorage.getItem("branch_id");

        let buffetList = [];
        if (tagItem.length > 0) {
            tagItem.map((data) => {
                buffetList.push(data.id)
            })
        } else {
            buffetList = null
        }
        let temp = [];
        for (let i in data) {
            if (data[i].priceGroup == undefined || data[i].priceGroup == null) {
                return AlertError(
                    `Please insert price at ${Number(i) + 1}`
                );
            }

            temp.push({
                channel_id: data[i].salesch_id,
                price_group: data[i].priceGroup
            });
        }
        var events = {
            type: "MENU",
            shop_id: Number(get_shop_id),
            branch_id: Number(get_branch_id),
            group_id,
            template_id: template_id ? template_id : null,
            template_name: template_name ? template_name : null,
            item_name,
            item_name_en: item_name_en ? item_name_en : null,
            item_name_cn: item_name_cn ? item_name_cn : null,
            item_name_jp: item_name_jp ? item_name_jp : null,
            item_name_kr: item_name_kr ? item_name_kr : null,
            item_name_id: item_name_id ? item_name_id : null,
            description: description ? description : null,
            description_en: description_en ? description_en : null,
            description_cn: description_cn ? description_cn : null,
            description_jp: description_jp ? description_jp : null,
            description_kr: description_kr ? description_kr : null,
            description_id: description_id ? description_id : null,
            description_receipt,
            item_image_path: item_image_path ? item_image_path : null,
            uom: uom ? uom : null,
            // uom: uom === 0 ? 0 : displayOrder,
            price: Number(price),
            enabled: state,
            printer_zone_id,
            display_order: displayOrder === 0 ? 0 : displayOrder,
            option_template: template_id ? [] : optionTemplate,
            buffet_list: buffetList,
            item_no: barcode.length == 0 ? null : barcode,
            stock_level_unit: stockLevelUnit ? stockLevelUnit : null,
            is_set_stock_level: stocklevel,
            is_view_only_employee: Boolean(visibleOnlyEmployee),
        };

        let apiUrls = `${baseURL}/management/menu`;
        let token = localStorage.getItem("Token");
        const resultAll = axios({
            method: "post",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: events,
        })
            .then(async (res) => {
                history.goBack();
                let menu_id = res.data.id;
                console.log("this is response menu_id=====>", menu_id);
                let newdata = {
                    shop_id: get_shop_id,
                    branch_id: get_branch_id,
                    menu_id: menu_id,
                    pricegroup_detail: temp
                }
                setPricegroup(newdata)
            })
            .catch((err) => {
                Swal.fire(
                    "Create !",
                    "Your can not create menu. !! " + err,
                    "error"
                );
            });

    };

    const setPricegroup = (data) => {
        let token = localStorage.getItem("Token");
        axios({
            method: "POST",
            url: `${baseURL}/management/itemsaleschannel`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: data,

        })
            .then((res) => {
                if (res.message) {
                } else {
                    console.log("this is response>>", res.data)
                }
            })
            .catch((err) => { });

    }
    const setPriceState = () => {
        console.log("this price==============>", price)
        let newPriceState = [];
        data.forEach(function (obj) {
            newPriceState.push(
                {
                    salesch_id: obj.salesch_id,
                    salesch_name_th: obj.salesch_name_th,
                    salesch_name_en: obj.salesch_name_en,
                    salesch_price: '',

                });
        });
        console.log("check data for setpricestate", newPriceState)
        console.log("this is data", data)
        let datacompare = data.filter(dataItem => newPriceState.some(newStateItem => newStateItem.salesch_id === dataItem.salesch_id))
        console.log("data return", datacompare);
        setData(prevState => {
            return prevState.map(pricelist => ({
                ...pricelist,
                priceGroup: price
            }));
        });


    }

    const setProps = () => {
        let token = localStorage.getItem("Token");
        axios({
            method: "get",
            url: `${baseURL}/management/saleschannel?limit=10000&page=1`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (res.data) {
                    console.log("this is response>>", res.data)
                    let temp = []
                    res.data.data.map((item, index) => {
                        console.log("this is item saleschannel>>>", item);
                        temp.push(
                            {
                                salesch_id: item.id,
                                salesch_name_th: item.salesch_name_th,
                                salesch_name_en: item.salesch_name_en,
                                channel: item.isDelivery,
                            });
                    });
                    setData(temp);
                    //console.log("this temp>>>>>>>>>>>",temp)
                }
            })
            .catch((err) => { });

    }

    const BindOption = (type) => {
        return (
            <>
                {optionTemplate.map((group, index) => {
                    console.log('group :', group);
                    let indexGroup = index
                    if (group.type == type) {
                        return (
                            <div className="option-box" key={index}>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <Switch
                                            checked={group.is_active}
                                            onChange={(e) => setOptionGroup(index, e, "is_active")}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                            className="input-cheked-show"
                                        />
                                        <span className="ml-3">
                                            {intl.formatMessage({
                                                id: "###",
                                                defaultMessage: "เปิด/ปิด option Group นี้",
                                            })}
                                        </span>
                                    </div>
                                    <div className={`group-name d-flex align-items-center${group.validate ? ' validate' : ''}`}>
                                        <span className="mr-5 name w-25">
                                            {intl.formatMessage({ id: "113121", defaultMessage: "group name TH" })}
                                        </span>
                                        <input
                                            className="flex-grow-1"
                                            type="text" minLength="100"
                                            value={group.option_group_name}
                                            onChange={(event) => setOptionGroup(index, event, "option_group_name")}
                                            disabled={!editTemplate} />
                                    </div>
                                    <div className={`group-name d-flex align-items-center${group.validate_en ? ' validate' : ''}`}>
                                        <span className="mr-5 name w-25">
                                            {intl.formatMessage({ id: "113122", defaultMessage: "group name EN" })}
                                        </span>
                                        <input
                                            className="flex-grow-1"
                                            type="text"
                                            minLength="100"
                                            value={group.option_group_name_en}
                                            onChange={(event) => setOptionGroup(index, event, "option_group_name_en")}
                                            disabled={!editTemplate} />
                                    </div>
                                    {/* <div className={`group-name d-flex align-items-center`}>
                                        {enable_cn && <div>
                                            <span className="mr-5 name">{intl.formatMessage({ id: "103124", defaultMessage: "group name CN" })}</span>
                                            <input type="text" minLength="100" value={group.option_group_name_cn} onChange={(event) => setOptionGroup(index, event, "option_group_name_cn")} disabled={!editTemplate} />
                                        </div>}

                                        <span className="option-delete d-flex text-primary ml-3 btn-custom" onClick={() => deleteGroup(index)}>
                                            <i className="fa fa-trash mr-3 text-primary"></i>
                                            {intl.formatMessage({ id: "104038", defaultMessage: "delete" })}
                                        </span>
                                    </div> */}
                                    <div className={`group-name d-flex align-items-center`}>
                                        <span className="mr-5 name w-25">
                                            {intl.formatMessage({ id: "103124", defaultMessage: "group name CN" })}
                                        </span>
                                        <input
                                            className="flex-grow-1"
                                            type="text" minLength="100"
                                            value={group.option_group_name_cn}
                                            onChange={(event) => setOptionGroup(index, event, "option_group_name_cn")}
                                            disabled={!editTemplate}
                                        />
                                    </div>
                                    <div className={`group-name d-flex align-items-center d-none`} >
                                        <span className="mr-5 name w-25">
                                            {intl.formatMessage({ id: "113560", defaultMessage: "group name JP" })}
                                        </span>
                                        <input
                                            className="flex-grow-1"
                                            type="text" minLength="100"
                                            value={group.option_group_name_jp}
                                            onChange={(event) => setOptionGroup(index, event, "option_group_name_jp")}
                                            disabled={!editTemplate}
                                        />
                                    </div>
                                    <div className={`group-name d-flex align-items-center d-none`}>
                                        <span className="mr-5 name w-25">
                                            {intl.formatMessage({ id: "113561", defaultMessage: "group name KR" })}
                                        </span>
                                        <input
                                            className="flex-grow-1"
                                            type="text" minLength="100"
                                            value={group.option_group_name_kr}
                                            onChange={(event) => setOptionGroup(index, event, "option_group_name_kr")}
                                            disabled={!editTemplate}
                                        />
                                    </div>
                                    <div className={`group-name d-flex align-items-center d-none`}>
                                        <span className="mr-5 name w-25">
                                            {intl.formatMessage({ id: "113562", defaultMessage: "group name IN" })}
                                        </span>
                                        <input
                                            className="flex-grow-1"
                                            type="text" minLength="100"
                                            value={group.option_group_name_id}
                                            onChange={(event) => setOptionGroup(index, event, "option_group_name_id")}
                                            disabled={!editTemplate}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <span
                                            className="option-delete d-flex text-primary ml-3 btn-custom"
                                            onClick={() => deleteGroup(index)}>
                                            <i className="fa fa-trash mr-3 text-primary"></i>
                                            {intl.formatMessage({ id: "104038", defaultMessage: "delete" })}
                                        </span>
                                    </div>
                                </div>
                                <div className="option-list overflow-auto">
                                    <div className="d-flex align-items-center mb-1 font-weight-bold">
                                        <span className="option-name col-2 mr-1">
                                            {intl.formatMessage({ id: "113566", defaultMessage: "status", })}
                                        </span>
                                        <span className="option-name col-4 mr-1">
                                            {intl.formatMessage({ id: "104039", defaultMessage: "Option name th" })}
                                        </span>
                                        <span className="option-name col-4 mr-1">
                                            {intl.formatMessage({ id: "103123", defaultMessage: "Option name en" })}
                                        </span>
                                        {/* {enable_cn && <span className="option-name col-3 mr-1">{intl.formatMessage({ id: "103125", defaultMessage: "Option name cn" })}</span>} */}
                                        <span className="option-name col-4 mr-1">
                                            {intl.formatMessage({ id: "103125", defaultMessage: "Option name cn" })}
                                        </span>
                                        <span className="option-name col-4 mr-1">
                                            {intl.formatMessage({ id: "113563", defaultMessage: "Option name jp" })}
                                        </span>
                                        <span className="option-name col-4 mr-1">
                                            {intl.formatMessage({ id: "113564", defaultMessage: "Option name kr" })}
                                        </span>
                                        <span className="option-name col-4 mr-1">
                                            {intl.formatMessage({ id: "113565", defaultMessage: "Option name in" })}
                                        </span>
                                        <span className="option-price col-2 mr-3">
                                            {intl.formatMessage({ id: "104040", defaultMessage: "price" })}</span>
                                        <span className="option-default mr-6">
                                            {intl.formatMessage({ id: "104041", defaultMessage: "show price" })}
                                        </span>
                                        <span className="option-default" style={{ minWidth: '51px' }}>
                                            {intl.formatMessage({ id: "113500", defaultMessage: "Set Default", })}
                                        </span>
                                    </div>
                                    {group.options.map((option, index) => {
                                        return (
                                            <div className="d-flex align-items-center mb-1" key={index}>
                                                <div className="option-name col-2 mr-1" maxLength="40">
                                                    <Switch
                                                        checked={option.is_active}
                                                        onChange={(e) => setOption(indexGroup, index, e, "is_active")}
                                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                                        className="input-cheked-show"
                                                    />
                                                </div>
                                                <input
                                                    className="option-name col-4 mr-1"
                                                    maxLength="120" type="text" value={option.option_name}
                                                    onChange={(event) => setOption(indexGroup, index, event, 'option_name')}
                                                    disabled={!editTemplate} />
                                                <input
                                                    className="option-name col-4 mr-1"
                                                    maxLength="120" type="text" value={option.option_name_en}
                                                    onChange={(event) => setOption(indexGroup, index, event, 'option_name_en')}
                                                    disabled={!editTemplate} />
                                                {/* {enable_cn && <input className="option-name col-3 mr-1" maxLength="120" type="text" value={option.option_name_cn} onChange={(event) => setOption(indexGroup, index, event, 'option_name_cn')} disabled={!editTemplate} />} */}
                                                <input
                                                    className="option-name col-4 mr-1"
                                                    maxLength="120" type="text" value={option.option_name_cn}
                                                    onChange={(event) => setOption(indexGroup, index, event, 'option_name_cn')}
                                                    disabled={!editTemplate} />
                                                <input
                                                    className="option-name col-4 mr-1"
                                                    maxLength="120" type="text" value={option.option_name_jp}
                                                    onChange={(event) => setOption(indexGroup, index, event, 'option_name_jp')}
                                                    disabled={!editTemplate} />
                                                <input
                                                    className="option-name col-4 mr-1"
                                                    maxLength="120" type="text" value={option.option_name_kr}
                                                    onChange={(event) => setOption(indexGroup, index, event, 'option_name_kr')}
                                                    disabled={!editTemplate} />
                                                <input
                                                    className="option-name col-4 mr-1"
                                                    maxLength="120" type="text" value={option.option_name_id}
                                                    onChange={(event) => setOption(indexGroup, index, event, 'option_name_id')}
                                                    disabled={!editTemplate} />
                                                <input
                                                    className="option-price col-2 mr-3" min="0"
                                                    type="number" value={option.option_price}
                                                    onChange={(event) => setOption(indexGroup, index, event, 'option_price')}
                                                    disabled={!editTemplate} />
                                                <input
                                                    className="option-default btn-custom ml-1"
                                                    type="checkbox" name={group.option_group_name}
                                                    checked={option.is_show_price}
                                                    onClick={(event) => setOption(indexGroup, index, event, 'is_show_price')}
                                                    disabled={!editTemplate} />
                                                <input
                                                    className="option-default btn-custom ml-12"
                                                    type="checkbox" name={group.option_group_name}
                                                    checked={option.is_default}
                                                    onClick={(event) => setOption(indexGroup, index, event, 'is_default')}
                                                    disabled={!editTemplate} />
                                                <span
                                                    className="option-delete d-flex ml-6 text-primary btn-custom"
                                                    onClick={() => deleteOption(indexGroup, index)}>
                                                    <i className="fa fa-trash mr-3 text-primary"></i>
                                                    {intl.formatMessage({ id: "104038", defaultMessage: "delete" })}
                                                </span>
                                            </div>
                                        )
                                    })}
                                    <span className="span-border mb-1 mt-3"></span>
                                    <span className="option-delete d-flex text-primary px-5 py-3 btn-custom" onClick={() => addOption(index)}>
                                        <i className="fa fa-plus-circle mr-3 text-primary"></i>
                                        {intl.formatMessage({ id: "104042", defaultMessage: "add option" })}
                                    </span>
                                    {type === 'multi' ? (
                                        <div className="">
                                            <div className="d-flex align-items-center mb-1 font-weight-bold">
                                                <span className="option-name col-3 mr-1">{intl.formatMessage({ id: "104044", defaultMessage: "Minimum" })}</span>
                                                <span className="option-price col-3">{intl.formatMessage({ id: "104045", defaultMessage: "Maximum" })}</span>
                                            </div>
                                            <div className="d-flex align-items-center mb-1">
                                                <input className="col-3 mr-1" type="number" min="0" value={group.option_pick_min} onChange={(event) => setOptionGroup(index, event, "option_pick_min")} disabled={!editTemplate} />
                                                <input className="col-3 " type="number" min="0" value={group.option_pick_max} onChange={(event) => setOptionGroup(index, event, "option_pick_max")} disabled={!editTemplate} />
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        )
                    }
                })}
                <span className="span-border mb-1 mt-3"></span>
                <span className="option-delete d-flex text-primary px-5 py-3 btn-custom" onClick={() => addGroup(type)}>
                    <i className="fa fa-plus-circle mr-3 text-primary"></i>
                    {intl.formatMessage({ id: "104017", defaultMessage: "add group" })}
                </span>
            </>
        )
    }

    const setOptionGroup = (index, event, group) => {
        if (group == "is_active") {
            var newState = [...optionTemplate];
            newState[index][group] = !newState[index][group];
            setOptionTemplate(newState);
        } else {
            var newState = [...optionTemplate];
            newState[index][group] = event.target.value;
            setOptionTemplate(newState);
        }

    }

    useEffect(() => {
        console.log("optionTemplate ==>", optionTemplate)
    }, [optionTemplate])

    const setOption = (indexGroup, index, event, option) => {
        let data
        var newState = [...optionTemplate];

        if (option == "is_active") {
            data = !newState[indexGroup].options[index][option];
        } else if (option != 'is_show_price' && option != 'is_default') {
            data = event.target.value
        } else {
            data = event.target.checked
        }
        newState[indexGroup].options[index][option] = data
        setOptionTemplate(newState)
    }

    const validateOptionGroupName = (optionGroupName) => {
        return optionGroupName && optionGroupName.trim() !== "";
    }

    const validateOptionName = (options) => {
        return options.length === 0 || (options[0]["option_name"] && options[0]["option_name"].trim() !== "");
    }

    const addOption = (index) => {
        const can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
        if (can_edit) {
            const newState = [...optionTemplate];
            const optionGroupName = newState[index]["option_group_name"];

            const isValidGroupName = validateOptionGroupName(optionGroupName);

            if (isValidGroupName) {
                newState[index]["validate"] = false
                newState[index]["options"].push({
                    id: null,
                    option_name: '',
                    option_name_en: '',
                    option_name_cn: '',
                    option_name_jp: '',
                    option_name_kr: '',
                    option_name_id: '',
                    option_price: 0,
                    is_show_price: true,
                    is_active: false
                });
            } else {
                newState[index]["validate"] = true;
                }

            setOptionTemplate(newState)
        }
    }

    const addGroup = (type) => {
        let can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
        if (can_edit) {
            const newState = [{
                id: null,
                option_group_name: '',
                option_group_name_en: '',
                option_group_name_cn: '',
                option_group_name_jp: '',
                option_group_name_kr: '',
                option_group_name_id: '',
                options: [],
                option_pick_min: type === "multi" ? 0 : 1,
                option_pick_max: type === "multi" ? 0 : 1,
                type,
                validate: false,
                validate_en: false,
                is_active: false
            }];
            setOptionTemplate([...optionTemplate, ...newState])
        }
    }

    const deleteGroup = (index) => {
        let can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
        if (can_edit) {
            var newState = [...optionTemplate];
            newState.splice(index, 1)
            setOptionTemplate(newState)
        }
    }
    const deleteOption = (indexGroup, index) => {
        let can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
        if (can_edit) {
            var newState = [...optionTemplate];
            newState[indexGroup].options.splice(index, 1)
            setOptionTemplate(newState)
        }
    }
    const updateFieldChanged = (index, value, name) => {
        console.log("set new array----->")
        let newArr = [...data]; // copying the old datas array
        newArr[index][name] = value; // replace e.target.value with whatever you want to change it to
        setData(newArr);
    };

    const clone = () => {
        let boo = !cloneTemplate
        // console.log('clone', boo);
        setCloneTemplate(boo)
        setEditTemplate(boo)
        if (boo) {
            setTemplate_id('')
        }

    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePass = (data) => {
        if (data === 'save') {
            getGroup()
        }
        handleClose()
    }

    const [openTranslate, setOpenTranslate] = useState(false)

    const handleTranslate = async () => {
        setIsLoading(true)

        if (!item_name || item_name.trim() === "") {
            return AlertError("please insert item name.");
        }

        // setOpenTranslate(true)

        try {
            const events = { item_name, description }
            let apiUrls = `${baseURL}/translate`;
            let token = localStorage.getItem("Token");

            const res = await axios({
                method: "post",
                url: apiUrls,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                data: events,
            })

            console.log(res.data)
            setItem_name_en(res.data.data?.item_name.translations.en)
            // setItem_name_cn(res.data.data?.item_name.translations["zh-cn"])
            setItem_name_cn(res.data.data?.item_name.translations.zh)
            setItem_name_jp(res.data.data?.item_name.translations.ja)
            setItem_name_kr(res.data.data?.item_name.translations.ko)
            setItem_name_id(res.data.data?.item_name.translations.hi)
            setDescription_en(res.data.data?.description?.translations.en)
            // setDescription_cn(res.data.data?.description?.translations["zh-cn"])
            setDescription_cn(res.data.data?.description?.translations.zh)
            setDescription_jp(res.data.data?.description?.translations.ja)
            setDescription_kr(res.data.data?.description?.translations.ko)
            setDescription_id(res.data.data?.description?.translations.hi)

            setOpenTranslate(true)

        } catch (error) {
            console.log(error.system_response.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "104014", defaultMessage: "Create New Menu" })}></CardHeader>
            <CardBody>
                {/*----------------menu detail---------------*/}
                <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                    {/* Group name */}
                    <div className="form-inline" style={{ marginTop: "25px", marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "104015", defaultMessage: "group name" })}
                        </p>
                        <div
                            className="d-flex"
                            style={{
                                width: "530px",
                            }}>
                            <select
                                as="select"
                                style={{
                                    flexGrow: "1",
                                    height: "38px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={group_id}
                                onChange={handleChangeGroup}
                            >
                                <option value="">{intl.formatMessage({ id: "104016", defaultMessage: "please select" })}</option>
                                {group &&
                                    group.map((result, index) => {
                                        return (
                                            <option key={index} value={result.id}>
                                                {result.group_name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <Button variant="light" className="ml-3 text-primary" onClick={handleShow}>
                                <i className="fa fa-plus-circle mr-3 text-primary" />
                                {intl.formatMessage({ id: "104017", defaultMessage: "add group" })}
                            </Button>

                            <Modal show={show} onHide={handleClose} animation={false}>
                                <Modal.Body>
                                    <CreateMenuGroupV3 type={'dialog'} state={filteredZone} handle={handlePass} />
                                </Modal.Body>

                            </Modal>
                        </div>
                    </div>
                    {/* Group name */}
                    {/* Thai name */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "104018", defaultMessage: "Thai menu name" })}
                        </p>
                        <input
                            value={item_name}
                            name="item_name"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(e) => setItem_name(e.target.value)}
                        />
                    </div>
                    {/* Thai name */}
                    {/* Thai description */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104020", defaultMessage: "Menu details" })}
                        </p>
                        <input
                            value={description}
                            name="description"
                            maxLength="500"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(e) => setDescription(e.target.value)
                            }
                        />
                    </div>
                    {/* Thai description */}
                    {/* English name  */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104019", defaultMessage: "English group name" })}
                        </p>
                        <input
                            value={item_name_en}
                            name="item_name_en"
                            maxLength="100"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(e) => setItem_name_en(e.target.value)}
                        />
                    </div>
                    {/* English name  */}
                    {/* English description */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104021", defaultMessage: "English menu details" })}
                        </p>
                        <input
                            value={description_en}
                            name="description_en"
                            maxLength="500"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(e) => setDescription_en(e.target.value)}
                        />
                    </div>
                    {/* English description */}

                    {/* More language Start */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <Accordion style={{
                            // width: "100%",
                            maxWidth: "725px",
                            marginTop: "15px", float: "left", borderRadius: "10px",
                            borderWidth: "0",
                            // width: "700px" 
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>
                                    {intl.formatMessage({ id: "113523", defaultMessage: "More languages" })}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="container pb-6" style={{ border: "1px solid", borderRadius: "10px", borderColor: "#CECDCD", paggingBottom: "20px", paddingTop: "20px" }}>
                                    {/* Chinese badges */}
                                    <div className="form-inline" >
                                        <span className="badge badge-pill badge-secondary p-4"
                                            style={{
                                                fontSize: "1.25rem",
                                                fontWeight: "bold"
                                            }}>
                                            {intl.formatMessage({ id: "113526", defaultMessage: "Chinese" })}
                                        </span>
                                    </div>
                                    {/* Chinese badges */}
                                    {/* Chinese name  */}
                                    <div className="form-inline">
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "103127", defaultMessage: "Chinese menu name" })}
                                        </p>
                                        <input
                                            value={item_name_cn}
                                            name="item_name_cn"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setItem_name_cn(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Chinese name  */}
                                    {/* Chinese description */}
                                    <div className="form-inline" >
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "103128", defaultMessage: "Chinese menu details" })}
                                        </p>
                                        <input
                                            value={description_cn}
                                            name="description_cn"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setDescription_cn(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Chinese description */}
                                    {/* Japanese badges */}
                                    <div className="form-inline mt-3" >
                                        <span className="badge badge-pill badge-secondary p-4"
                                            style={{
                                                fontSize: "1.25rem",
                                                fontWeight: "bold"
                                            }}>
                                            {intl.formatMessage({ id: "113527", defaultMessage: "Japanese" })}
                                        </span>
                                    </div>
                                    {/* Japanese badges */}
                                    {/* Japanese name  */}
                                    <div className="form-inline" >
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "113517", defaultMessage: "Japanese menu name" })}
                                        </p>
                                        <input
                                            value={item_name_jp}
                                            name="item_name_jp"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setItem_name_jp(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Japanese name  */}
                                    {/* Japanese description */}
                                    <div className="form-inline">
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "113518", defaultMessage: "Japanese menu details" })}
                                        </p>
                                        <input
                                            value={description_jp}
                                            name="description_jp"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setDescription_jp(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Japanese description */}
                                    {/* Korean badges */}
                                    <div className="form-inline mt-3" >
                                        <span className="badge badge-pill badge-secondary p-4"
                                            style={{
                                                fontSize: "1.25rem",
                                                fontWeight: "bold"
                                            }}>
                                            {intl.formatMessage({ id: "113528", defaultMessage: "Korean" })}
                                        </span>
                                    </div>
                                    {/* Korean badges */}
                                    {/* Korean name  */}
                                    <div className="form-inline" >
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "113519", defaultMessage: "Korean menu name" })}
                                        </p>
                                        <input
                                            value={item_name_kr}
                                            name="item_name_kr"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setItem_name_kr(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Korean name  */}
                                    {/* Korean description */}
                                    <div className="form-inline">
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "113520", defaultMessage: "Korean menu details" })}
                                        </p>
                                        <input
                                            value={description_kr}
                                            name="description_kr"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setDescription_kr(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Korean description */}
                                    {/* Indian badges */}
                                    <div className="form-inline mt-3" >
                                        <span className="badge badge-pill badge-secondary p-4"
                                            style={{
                                                fontSize: "1.25rem",
                                                fontWeight: "bold"
                                            }}>
                                            {intl.formatMessage({ id: "113529", defaultMessage: "Indian" })}
                                        </span>
                                    </div>
                                    {/* Indian badges */}
                                    {/* Indian name  */}
                                    <div className="form-inline">
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "113521", defaultMessage: "Indian menu name" })}
                                        </p>
                                        <input
                                            value={item_name_id}
                                            name="item_name_id"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setItem_name_id(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Indian name  */}
                                    {/* Indian description */}
                                    <div className="form-inline" >
                                        <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                                            {intl.formatMessage({ id: "113522", defaultMessage: "Indian menu details" })}
                                        </p>
                                        <input
                                            value={description_id}
                                            name="description_id"
                                            maxLength="100"
                                            style={{
                                                height: "38px",
                                                width: "470px",
                                                backgroundColor: "#F4F6F9",
                                                borderRadius: "5px",
                                                borderWidth: "0",
                                            }}
                                            onChange={(e) => setDescription_id(e.target.value)
                                            }
                                        />
                                    </div>
                                    {/* Indian description */}
                                </div>
                                {/* </CardBody> */}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    {/* More language End */}

                    {/* Receipt name */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "104022", defaultMessage: "The menu name on the receipt" })}
                        </p>
                        <input
                            name="description_receipt"
                            maxLength="50"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(description_receipt) =>
                                setDescription_receipt(description_receipt.target.value)
                            }
                        />
                    </div>
                    {/* Receipt name */}
                    {/* Display order */}
                    <div className="form-inline" style={{ marginBottom: '25px' }}>
                        <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "104023", defaultMessage: "display order" })}:{' '}
                        </p>
                        <input
                            name="displayOrder"
                            min="0"
                            type="number"
                            style={{
                                height: '38px',
                                width: '530px',
                                backgroundColor: '#F4F6F9',
                                borderRadius: '5px',
                                borderWidth: '0'
                            }}
                            value={displayOrder}
                            onChange={displayOrder =>
                                setDisplayOrder(displayOrder.target.value)
                            }
                        />
                    </div>
                    {/* Display order */}
                    {/* Image path and button upload/edit and delete */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104024", defaultMessage: "image" })} : <br></br>
                            (512*512)
                        </p>
                        <div
                            className="d-flex"
                            style={{
                                width: "530px",
                            }}>
                            <input
                                name="item_image_path"
                                style={{
                                    flexGrow: "1",
                                    height: "38px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                disabled
                                value={item_image_path || ""}
                            />

                            <span className="btn btn-primary btn-file wbt" style={{
                                whiteSpace: "nowrap",
                                width: "fit-content",
                                paddingTop: "12px"
                            }}>
                                {intl.formatMessage({ id: "104025", defaultMessage: "Upload/Edit" })}
                                <input type="file" onChange={handleChangeItem_image_path} />
                            </span>

                            <button
                                type="button"
                                className="btn btn-danger wbt"
                                onClick={removeImageItem_image_path}
                            >
                                {intl.formatMessage({ id: "104026", defaultMessage: "delete" })}
                            </button>
                        </div>
                    </div>
                    {/* Image path and button upload/edit and delete */}
                    {/* image preview, cancel and upload */}
                    <div
                        style={{
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "50%",
                        }}
                    >
                        {stateItem_image_path.previewItem_image_path ? (
                            <img
                                style={{
                                    width: "300px",
                                    height: "300px",
                                }}
                                src={stateItem_image_path.previewItem_image_path}
                            />
                        ) : item_image_path ? (
                            <img
                                style={{
                                    width: "300px",
                                    height: "300px",
                                }}
                                src={item_image_path}
                            />
                        ) : null}
                    </div>
                    {stateItem_image_path.imageURLItem_image_path ? (
                        <CropperImage
                            image={stateItem_image_path.imageURLItem_image_path}
                            handleCancel={handleCancelUploadItem_image_path}
                            handleUpload={handleImageUploadItem_image_path}
                            scale={1}
                        />
                    ) : (
                        ""
                    )}
                    {/* image preview, cancel and upload */}
                    {/* unit */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104027", defaultMessage: "unit" })}
                        </p>
                        <input
                            name="uom"
                            maxLength="50"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(uom) => setUom(uom.target.value)}
                        />
                    </div>
                    {/* unit */}
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "113512", defaultMessage: "visible only to employee" })}
                        </p>
                        <Switch
                            checked={visibleOnlyEmployee}
                            onChange={handleVisibility}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            className="input-cheked-show"
                        />
                    </div>
                </div>
                {/*----------------price---------------*/}
                <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                    <div className="h6" style={{ paddingTop: "20px" }}>

                        {intl.formatMessage({
                            id: "104028",
                            defaultMessage: "price",
                        })}

                    </div>

                    <p style={{ marginTop: "15px", width: "50%" }}>
                        <CheckBox
                            checked={isChecked}
                            onChange={handleChangeCheckPrice}
                        />
                        {intl.formatMessage({
                            id: "113296",
                            defaultMessage: "Same price for all channels",
                        })}
                    </p>
                    <div
                        style={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            // fontSize: "15px",
                        }}
                    >
                        {intl.formatMessage({
                            id: "113298",
                            defaultMessage: "Offline channel",
                        })}
                    </div>
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "104028", defaultMessage: "price" })}:
                        </p>
                        <input
                            name="price"
                            type="number"
                            min="0"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            onChange={(price) => setPrice(price.target.value)}
                        />
                    </div>
                    {data.map((item, index) => {
                        if (!item.channel) {
                            return renderInputField(item, index);
                        }
                        return null;
                    })}
                    <div
                        style={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            // fontSize: "15px",
                        }}
                    >
                        {intl.formatMessage({
                            id: "113297",
                            defaultMessage: "Delivery channel",
                        })}
                    </div>
                    {data.map((item, index) => {
                        if (item.channel) {
                            // isDelivery เป็น true
                            return renderInputField(item, index);
                        }
                        return null;
                    })}
                </div>
                {/*----------------barcode---------------*/}

                <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                    <div className="form-inline" style={{ marginTop: "25px", marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            <span style={{ color: "red" }}> </span>
                            {intl.formatMessage({
                                id: "113239",
                                defaultMessage: "Barcode",
                            })}
                            :{" "}
                        </p>
                        <input
                            name="price"
                            style={{
                                height: "38px",
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            value={barcode}
                            onChange={(barcode) => setBarcode(barcode.target.value)}
                        />
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            <span style={{ color: "red" }}>
                                *
                            </span>
                            {intl.formatMessage({ id: "104029", defaultMessage: "printer zone" })}
                        </p>
                        {/*----------------printerzone---------------*/}

                        {zoneprinter_data.length > 0 ? (
                            <div>
                                <Autocomplete
                                    id="zone printer"
                                    options={zoneprinter_data}
                                    getOptionLabel={(option) => intl.locale == 'th' ? option.zone_name : option.zone_name_en}
                                    style={{
                                        height: "38px",
                                        width: "530px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    onChange={handleChangezoneprinter}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.inputColor}
                                            style={{
                                                borderWidth: 0,
                                            }}
                                            label={intl.formatMessage({ id: "104030", defaultMessage: "Select Zone " })}

                                        />
                                    )}
                                />
                            </div>
                        ) : null}

                    </div>
                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "104031", defaultMessage: "Buffet" })}
                        </p>
                        <Autocomplete
                            multiple
                            id="grouped-demo"
                            options={tagData}
                            getOptionLabel={(option) => option.item_name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={tagItem}
                            onChange={(event, newValue) => {
                                setTagItem(newValue);
                            }}
                            style={{
                                width: "530px",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            renderInput={(params) =>
                                <TextField {...params} variant="outlined" />
                            }
                        />
                    </div>
                </div>

                {/*----------------option---------------*/}
                <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                            {intl.formatMessage({ id: "104032", defaultMessage: "Options" })}
                        </p>
                        <div
                            style={{
                                width: "530px",
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <Switch
                                    checked={changeOption}
                                    onChange={() => handleChangeOption()}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                    className="input-cheked-show"
                                />
                                {/*<span className="ml-3">{intl.formatMessage({ id: "104033", defaultMessage: "Options for the food menu." })}</span>*/}
                            </div>

                            <div className={`box-cheked-show${changeOption ? ' d-block' : ''}`}>
                                <div className="template mb-3 d-flex align-items-center"
                                    style={{
                                        width: "530px",
                                    }}>
                                    <span className="mr-3">
                                        {intl.formatMessage({ id: "104034", defaultMessage: "pattern" })}
                                    </span>
                                    <select
                                        as="select"
                                        style={{
                                            flexGrow: "1",
                                            height: "38px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        value={template_id}
                                        onChange={handleChangeTemplate}
                                    >
                                        <option value="">{intl.formatMessage({ id: "104035", defaultMessage: "please select" })}</option>
                                        {template &&
                                            template.map((result, index) => {
                                                return (
                                                    <option key={index} value={result.id}>
                                                        {result.template_name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <div alt="clone template" className={`button-clone d-none${(template_id !== '' && template_id !== undefined) ? ' d-flex' : ''}${cloneTemplate ? ' active' : ''}`}>
                                        <i className="fa fa-clone" onClick={() => clone()}></i>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Switch
                                        checked={changeName}
                                        onChange={() => handleChangeNameOption()}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                        className="input-cheked-show"
                                    />
                                    <span className="ml-3">กำหนดชื่อรูปแบบ</span>
                                </div>

                                <div className={`template name mb-3 align-items-center${changeName ? ' d-flex' : ''}`}
                                    style={{
                                        width: "530px",
                                    }}>
                                    <span className="mr-3">
                                        ชื่อรูปแบบ
                                    </span>
                                    <input
                                        name="template name"
                                        type="text"
                                        min="0"
                                        style={{
                                            height: "38px",
                                            flexGrow: "1",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        onChange={(templateName) => setTemplateName(templateName.target.value)}
                                    />
                                </div>

                                <div className="single-choise mb-4">
                                    <span className="title bg-light">
                                        {intl.formatMessage({ id: "104036", defaultMessage: "Single option" })}
                                    </span>
                                    {BindOption('single')}
                                </div>
                                <div className="multi-choise">
                                    <span className="title bg-light">
                                        {intl.formatMessage({ id: "104043", defaultMessage: "Multiple Option" })}
                                    </span>
                                    {BindOption('multi')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*----------------enable---------------*/}
                <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "104046", defaultMessage: "enable" })}
                        </p>
                        <Switch
                            checked={state}
                            onChange={() => handleChangeSwitch()}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>
                </div>
                {/* ------------------stocklevel---------------- */}
                <div className="h6" style={{ paddingTop: "20px" }}>

                    {intl.formatMessage({
                        id: "113340",
                        defaultMessage: "Stock Level",
                    })}

                </div>
                <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>

                    <div className="form-inline" style={{ marginBottom: "25px" }}>

                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "113337", defaultMessage: "Set Stock Level" })}:{" "}
                        </p>
                        <Switch
                            checked={stocklevel}
                            onChange={() => handleChangeStockLevel()}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                    </div>
                    <div className={`template name mt-3 mb-3 align-items-center${stocklevel ? ' d-flex' : ''}`}
                        style={{
                            width: "530px",
                        }}>
                        <span style={{ color: "red" }}>
                            *
                        </span>
                        <span className="mr-3">
                            {intl.formatMessage({ id: "113338", defaultMessage: "How Many Units Do You Currently Have?" })}:{" "}

                        </span>
                        <input
                            name="stocklevelunit"
                            type="text"
                            min="0"
                            style={{
                                height: "38px",
                                flexGrow: "1",
                                backgroundColor: "#F4F6F9",
                                borderRadius: "5px",
                                borderWidth: "0",
                            }}
                            placeholder={intl.formatMessage({ id: "113339", defaultMessage: "e.g. 100" })}

                            onChange={(stockLevelUnit) => setStockLevelUnit(stockLevelUnit.target.value)}
                        />
                    </div>
                </div>

                <div
                    className="form-inline"
                    style={{
                        marginBottom: "25px",
                    }}
                >
                    <div
                        style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                        }}
                    ></div>
                    <Link to="/MenuNew">
                        <button type="button" className="btn btn-secondary wbt mr-5">
                            {intl.formatMessage({ id: "104047", defaultMessage: "Cancel" })}
                        </button>
                    </Link>
                    <button className="btn btn-primary" onClick={() => onSubmit()}>
                        {intl.formatMessage({ id: "104048", defaultMessage: "Save" })}
                    </button>
                </div>
            </CardBody>
        </Card>
    );
};
