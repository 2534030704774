import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { baseURL } from "../../../service/API.js";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import FormGroup from "@material-ui/core/FormGroup";

export const EditMaterial = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(
    intl.formatMessage({ id: "113234", defaultMessage: "Edit Material" })
  );
  const history = useHistory();
  const [data, setData] = useState([]);
  const [material_unit_id, setMaterial_unit_id] = useState(null);
  const [name, setName] = useState(null);
  const [nameEN, setNameEN] = useState(null);
  const [description, setDescription] = useState(null);
  const [price_per_main_unit, setPrice_per_main_unit] = useState(0);
  const [unit, setUnit] = useState(null)
  const [listUnit, setListUnit] = useState([]);
  const [shop, setShop] = useState([]);
  const [material_unit_convert_id, setMaterial_unit_convert_id] = useState(null);
  const [listUnitConvertFilter, setListUnitConvertFilter] = useState([]);
  const [listUnitConvert, setListUnitConvert] = useState([]);
  const [reorderlevel, setOrderlevel] = useState(0);

  const [listUnitAlert, setListUnitAlert] = useState([])
  const [alertMinimumUnit, setAlertMinimumUnit] = useState(null)
  const [alertMinimumUnitId, setAlertMinimumUnitId] = useState(null)

  useEffect(() => {
    console.log(`****** props ******`, props)
    setName(props.location.state.row.name)
    setNameEN(props.location.state.row.name_en)
    setDescription(props.location.state.row.description)
    setPrice_per_main_unit(props.location.state.row.price_per_main_unit)
    setOrderlevel(props.location.state.row.reorderlevel)
    setMaterial_unit_id(props.location.state.row.material_unit_id)
    setAlertMinimumUnitId(props.location.state.row.reorder_material_unit_id)
    getListConvert();
    getListUnitConvert()
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const mainUnit = data[0]?.main_unit;
      const subUnit = data[0]?.sub_unit;

      if (mainUnit && subUnit) {
        setListUnitAlert([mainUnit, subUnit]);
      }
    }
  }, [data]);


  const getListUnitConvert = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit_convert?time=1&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListUnitConvert(res.data.data);
          setListUnitConvertFilter(res.data.data);
          let data = []
          data = res.data.data.filter(x => x.id == props.location.state.row.unit_convert_id)
          setMaterial_unit_convert_id(data[0])
          setData(data)
        }
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };
  //'TEXTBOX','DDL','CHECKBOX','IMAGE'
  const getListConvert = async () => {
    let token = localStorage.getItem("Token");
    axios({
      method: "get",
      url: `${baseURL}/management/stock/material_unit?time=1&page=1&limit=1000`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.message) {
        } else {
          setListUnit(res.data.data);
          // setAlertMinimumUnitId(67)
          const data = res.data.data.filter(x => x.id == props.location.state.row.material_unit_id)
          // console.log(data)
          setUnit(data[0])

          const dataAlert = res.data.data.filter(x => x.id == props.location.state.row.reorder_material_unit_id)
          // console.log(dataAlert)
          setAlertMinimumUnit(dataAlert[0])
        }
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const onSubmit = async () => {
    
    if (name == undefined || name == "" || name == null) {
      return AlertError("Please insert name");
    }
    if (nameEN == undefined || nameEN == "" || nameEN == null) {
      return AlertError("Please insert english name");
    }
    if (description == undefined || description == "" || description == null) {
      return AlertError("Please insert description");
    }
    if (price_per_main_unit == undefined || price_per_main_unit == "" || price_per_main_unit == null) {
      return AlertError("Please insert price");
    }
    if (price_per_main_unit < 0) {
      return AlertError(`${intl.formatMessage({ id: "113541", defaultMessage: "Please enter a valid price (greater than 0).", })}`);
    }
    if (material_unit_id == undefined || material_unit_id == "" || material_unit_id == null) {
      return AlertError("Please insert material unit");
    }
    if (material_unit_convert_id == undefined || material_unit_convert_id == "" || material_unit_convert_id == null) {
      return AlertError("Please insert convert unit");
    }
    if (reorderlevel === undefined || reorderlevel === "" || reorderlevel === null) {
      return AlertError("Please insert Alert Minimum");
    }
    if (reorderlevel < 0) {
      return AlertError(`${intl.formatMessage({ id: "113542", defaultMessage: "Please enter a positive value for Alert Minimum.", })}`);
    }
    if (alertMinimumUnit === undefined || alertMinimumUnit === "" || alertMinimumUnit === null) {
      return AlertError("Please insert Alert Minimum Unit");
    }
    const shop_id = localStorage.getItem('shop_id')
    const branch_id = localStorage.getItem('branch_id')

    var events = {
      shop_id: shop_id,
      branch_id: branch_id,
      material_unit_id: material_unit_id,
      name: name,
      name_en: nameEN,
      description: description,
      price_per_main_unit: price_per_main_unit,
      unit_convert_id: material_unit_convert_id.id,
      reorderlevel:reorderlevel,
      reorder_material_unit_id: alertMinimumUnitId
    };
    let apiUrls = `${baseURL}/management/stock/material/${props.location.state.row.id}`;
    let token = localStorage.getItem("Token");
    console.log(events)
    Swal.fire({
      title: "",
      text: "Confirm edit",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "put",
          url: apiUrls,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: events,
        })
          .then(async (res) => {
            history.goBack();
          })
          .catch((err) => {
            const errorMessage = err.response?.data?.system_response?.message || "Your can not edit. !! ";
             Swal.fire(
                        "Error!",
                        errorMessage,
                        "error"
                );
          });
      }
    });

    return false;
  };

  const handleUnitChange = (newValue) => {
    if (newValue) {
      setMaterial_unit_id(newValue.id);
      setUnit(newValue)

      // หา convert unit ที่สัมพันธ์กับ unit
      const relatedConvertUnit = listUnitConvert.find((item) => item.main_unit.id === newValue.id);

      setMaterial_unit_convert_id(relatedConvertUnit || null); // ตั้งค่าหรือรีเซ็ต

      // ดึงข้อมูล mainUnit และ subUnit ใหม่จาก listUnitConvert
      const relatedUnitAlert = listUnitConvert.filter((item) => item.main_unit.id === newValue.id);

      if (relatedUnitAlert.length > 0) {
        const mainUnit = relatedUnitAlert[0]?.main_unit;
        const subUnit = relatedUnitAlert[0]?.sub_unit;

        if (mainUnit) {
          if (subUnit && mainUnit.id !== subUnit.id) {
            setListUnitAlert([mainUnit, subUnit]);
          } else {
            setListUnitAlert([mainUnit]); // เพิ่มเฉพาะ mainUnit หาก id เท่ากัน
          }

          setAlertMinimumUnit(mainUnit);
          setAlertMinimumUnitId(mainUnit.id);
        } else {
          setListUnitAlert([]);
          setAlertMinimumUnit(null);
          setAlertMinimumUnitId(null);
        }
      } else {
        setListUnitAlert([]);
        setAlertMinimumUnit(null);
        setAlertMinimumUnitId(null);
      }
    } else {
      // Reset หาก newValue เป็น null
      setUnit(null);
      setMaterial_unit_id(null);
      setListUnitConvertFilter([]);
      setMaterial_unit_convert_id(null);
      setListUnitAlert([]);
      setAlertMinimumUnit(null);
      setAlertMinimumUnitId(null);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113234",
            defaultMessage: "Edit Material",
          })}
        >
          <CardHeaderToolbar>
            {/*<Link to="/TargetlistTopic">
                            <button type="button" clas  sName="btn btn-primary">
                                Manage Target list
                            </button>
                        </Link>*/}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113214",
                defaultMessage: "Name (TH)",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113215",
                defaultMessage: "Name (EN)",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={nameEN}
              onChange={(e) => setNameEN(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113216",
                defaultMessage: "description",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113217",
                defaultMessage: "price/unit (Baht)",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              type="number"
              name="modelGroup"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={price_per_main_unit}
              onChange={(e) => setPrice_per_main_unit(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113218",
                defaultMessage: "unit",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              freeSolo
              disabled
              id="grouped-demo"
              options={listUnit}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option == value}
              value={unit}
              onChange={(event, newValue) => {
                // console.log(newValue);
                handleUnitChange(newValue)
                //updateFieldChanged(index, newValue, 'permission')
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113233",
                defaultMessage: "convert unit",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              freeSolo
              disabled
              id="grouped-demo"
              options={[]}
              value={material_unit_convert_id}
              getOptionLabel={(option) => `${option.main_unit.name}-${option.sub_unit.name}`}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  disabled
                  style={{ pointerEvents: "none" }} 
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null, 
                  }}
                />
              )}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113537",
                defaultMessage: "Alert Minimum",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <input
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={reorderlevel}
              onChange={(e) => setOrderlevel(e.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({
                id: "113538",
                defaultMessage: "Alert Minimum Unit",
              })}
              <span style={{ color: "red" }}>*</span>:{" "}
            </p>
            <Autocomplete
              id="grouped-demo"
              options={listUnitAlert}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option == value}
              value={alertMinimumUnit}
              onChange={(event, newValue) => {
                console.log(`&&&&&&& alertMinimumUnitId &&&&&`, newValue);
                if (newValue) {
                  // กรณีที่ผู้ใช้เลือกค่าใหม่
                  setAlertMinimumUnitId(newValue.id);
                  setAlertMinimumUnit(newValue);
                } else {
                  // กรณีที่ผู้ใช้ลบค่า (newValue === null)
                  setAlertMinimumUnitId(null);
                  setAlertMinimumUnit(null);
                }
                //updateFieldChanged(index, newValue, 'permission')
              }}
              // disableClearable={false} // หรือ true หากไม่ต้องการให้ลบค่า
              disableClearable={!alertMinimumUnit} 
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) => (
                <TextField {...params}
                  placeholder={intl.formatMessage({ id: "113540", defaultMessage: "Please select alert minimum unit", })}
                  variant="outlined" />
              )}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/Material">
              <button type="button" className="btn btn-secondary wbt mr-5">
                {intl.formatMessage({ id: "113038", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "113031", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
