import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "./component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const Payment = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    const classes = useStyles(); 
    suhbeader.setTitle(intl.formatMessage({ id: "113306", defaultMessage: "Payment" }));
    const history = useHistory();
    const [cashstatus, setCashStatus] = useState();
    const [qrcodestatus, setQRCodeStatus] = useState();
    const [creditstatus, setCreditStatus] = useState();


    const [name, setname] = useState();
    const [name_en, setname_en] = useState();
    const [description, setdescription] = useState();
    const [description_en, setdescription_en] = useState();
    const [address, setaddress] = useState();
    const [address_en, setaddress_en] = useState();
    const [tel, settel] = useState();
    const [email, setemail] = useState();
    const [open, setopen] = useState();
    const [close, setclose] = useState();
    const [qr_image, setQr_image] = useState();
    const AlertError = (message) => {
        Swal.fire("Payment!", message, "error");
    };

    useEffect(() => {
        getBranch();
    }, []);

    const getBranch = async () => {
        axios({
            method: "get",
            url: `${baseURL}/management/Branch`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                setname(res.data.data.data.name);
                setname_en(res.data.data.data.name_en);
                setdescription(res.data.data.data.description);
                setdescription_en(res.data.data.data.description_en);
                setaddress(res.data.data.data.address);
                setaddress_en(res.data.data.data.address_en);
                settel(res.data.data.data.tel);
                setemail(res.data.data.data.email);
                setopen(res.data.data.data.open);
                setclose(res.data.data.data.close);
                setQr_image(res.data.data.data.qr_image);
                setCashStatus(res.data.data.data.is_payment_type_cash)
                setCreditStatus(res.data.data.data.is_payment_type_creditcard)
                setQRCodeStatus(res.data.data.data.is_payment_type_qr_code)
                
            
            }
        });
    };



    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113306", defaultMessage: "Payment" })}>
                <CardHeaderToolbar>
                    <Link to="/EditPayment">
                        <button type="button" className="btn btn-primary">
                            {intl.formatMessage({ id: "108014", defaultMessage: "edit" })}
                        </button>
                    </Link>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="container">
                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "113307", defaultMessage: "Cash:" })}:{" "}
                        </p>
                        {cashstatus ? (
                        <span 
                        >{intl.formatMessage({ id: "113310", defaultMessage: "Open" })}
                        </span>
                          ) : (
                        <span>{intl.formatMessage({ id: "113311", defaultMessage: "Close" })}
                        </span>
                          )}
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "113308", defaultMessage: "QR Code:" })}:{" "}
                        </p>
                        {qrcodestatus ? (
                        <span 
                        >{intl.formatMessage({ id: "113310", defaultMessage: "Open" })}
                        </span>
                          ) : (
                        <span>{intl.formatMessage({ id: "113311", defaultMessage: "Close" })}
                        </span>
                          )}
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "113309", defaultMessage: "Credit Card:" })}:{" "}
                        </p>
                        {creditstatus  ? (
                        <span 
                        >{intl.formatMessage({ id: "113310", defaultMessage: "Open" })}
                        </span>
                          ) : (
                        <span>{intl.formatMessage({ id: "113311", defaultMessage: "Close" })}
                        </span>
                          )}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
