import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "./component.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const Branch = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    const classes = useStyles();
    suhbeader.setTitle(intl.formatMessage({ id: "108013", defaultMessage: "branch" }));
    const history = useHistory();
    const [name, setname] = useState();
    const [name_en, setname_en] = useState();
    const [description, setdescription] = useState();
    const [description_en, setdescription_en] = useState();
    const [address, setaddress] = useState();
    const [address_en, setaddress_en] = useState();
    const [tel, settel] = useState();
    const [email, setemail] = useState();
    const [open, setopen] = useState();
    const [close, setclose] = useState();
    const [qr_image, setQr_image] = useState();
    const AlertError = (message) => {
        Swal.fire("Branch!", message, "error");
    };

    useEffect(() => {
        getBranch();
    }, []);

    const getBranch = async () => {
        axios({
            method: "get",
            url: `${baseURL}/management/Branch`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                setname(res.data.data.data.name);
                setname_en(res.data.data.data.name_en);
                setdescription(res.data.data.data.description);
                setdescription_en(res.data.data.data.description_en);
                setaddress(res.data.data.data.address);
                setaddress_en(res.data.data.data.address_en);
                settel(res.data.data.data.tel);
                setemail(res.data.data.data.email);
                setopen(res.data.data.data.open);
                setclose(res.data.data.data.close);
                setQr_image(res.data.data.data.qr_image);
            }
        });
    };



    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "108013", defaultMessage: "branch" })}>
                <CardHeaderToolbar>
                    <Link to="/EditBranch">
                        <button type="button" className="btn btn-primary">
                            {intl.formatMessage({ id: "108014", defaultMessage: "edit" })}
                        </button>
                    </Link>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <div className="container">
                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {/* <span style={{ color: "red" }}>*</span> */}
                            {intl.formatMessage({ id: "108005", defaultMessage: "Thai name:" })}:{" "}
                        </p>
                        <span
                        >{name}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108006", defaultMessage: "English name:" })}:{" "}
                        </p>
                        <span
                        >{name_en}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108007", defaultMessage: "Details:" })}:{" "}
                        </p>
                        <span
                        >{description}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108008", defaultMessage: "English details:" })}:{" "}
                        </p>
                        <span
                        >{description_en}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108009", defaultMessage: "Address:" })}:{" "}
                        </p>
                        <span
                        >{address}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108010", defaultMessage: "English address:" })}:{" "}
                        </p>
                        <span
                        >{address_en}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108022", defaultMessage: "Phone number:" })}:{" "}
                        </p>
                        <span
                        >{tel}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108023", defaultMessage: "Email:" })}:{" "}
                        </p>
                        <span
                        >{email}
                        </span>
                    </div>

                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                        }}
                    >
                        <p
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        >
                            {intl.formatMessage({ id: "108029", defaultMessage: "opening time" })} :{" "}
                        </p>
                        <span
                        >{open} - {close}
                        </span>
                    </div>

                    {qr_image &&
                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    marginBottom: "155px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113240", defaultMessage: "QR" })} :{" "}
                            </p>
                            <img
                                style={{
                                    width: "175px",
                                    height: "175px",
                                }}
                                src={qr_image}
                            />
                        </div>}
                </div>
            </CardBody>
        </Card>
    );
};
